import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Input,
  OnChanges,
  SimpleChanges,
  inject,
} from '@angular/core';
import {
  BreakpointObserver,
  Breakpoints,
  LayoutModule,
} from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { SwaggerUIBundle, SwaggerUIStandalonePreset } from 'swagger-ui-dist';

@Component({
  selector: 'cca-swagger',
  standalone: true,
  imports: [LayoutModule],
  templateUrl: './swagger.component.html',
  styleUrls: ['./swagger.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwaggerComponent implements AfterViewInit, OnChanges {
  private breakpointObserver = inject(BreakpointObserver);
  @ViewChild('swagger') swaggerDom: ElementRef<HTMLDivElement> | undefined;

  @Input() swaggerUrl: undefined | string;
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay(),
    );

  ngAfterViewInit(): void {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['swaggerUrl'].firstChange) {
      this.init();
    }
  }

  init() {
    if (this.swaggerUrl && this.swaggerDom) {
      SwaggerUIBundle({
        urls: [
          {
            name: 'V1',
            url: this.swaggerUrl,
          },
        ],
        domNode: this.swaggerDom?.nativeElement,
        deepLinking: true,
        presets: [SwaggerUIBundle['presets'].apis, SwaggerUIStandalonePreset],
        layout: 'StandaloneLayout',
      });
    }
  }
}
